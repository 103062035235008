//////////////////////
//About
//////////////////////

.about {
  position            : relative;
  background-image    : url('../assets/img/tour.jpg');
  background-size     : cover;
  background-position : center center;
  color               : $lynch;

  /* &:after {
     width      : 100%;
     height     : 100%;
     content    : '';
     position   : absolute;
     top        : 0;
     left       : 0;
     background : url('../assets/img/grid.png');
     z-index    : 5;
   } */

  &:before {
    width      : 100%;
    height     : 100%;
    content    : '';
    position   : absolute;
    top        : 0;
    left       : 0;
    background : rgba($sail, 0.9);
    z-index    : 4;
  }

  .title--secondary {
    position   : relative;
    z-index    : 10;
    text-align : center;
  }

  .layout__item {
    @include font-size(12);
    font-family : $font--main;
    line-height : 30px;
    position    : relative;
    z-index     : 10;
  }
}