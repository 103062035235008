//////////////////////
//Footer
//////////////////////
footer{
	margin-top: 80px;
	text-align: center;
	@include font-size(8);
	
	[class^='icon-']{
		@include font-size(50);
	}
	
	.footer__logo{
      display: inline-block;
		width: 200px;
	}
  .minzeven{
    width: 70px;
  }
  .trlink{
    width: 70px;
  }
  .petitclos{
    width: 70px;
  }
}