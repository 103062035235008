.band {
  padding-top    : 50px;
  padding-bottom : 50px;

  @include breakpoint($screen-medium) {
    padding-top    : 100px;
    padding-bottom : 100px;
  }

  &--background {
    color : white;

    .layout {
      z-index : 10;
    }
  }


}

.wrapper {
  max-width     : $wrapper-width;
  margin        : 0 auto;
  padding-left  : $grid-gutter-width;
  padding-right : $grid-gutter-width;

  @include breakpoint($screen-medium) {
    padding-left  : ($grid-gutter-width * 2);
    padding-right : ($grid-gutter-width * 2);
  }
}

.layout {
  margin-left : (-1 * $grid-gutter-width);
  position    : relative;

  &__item {
    display        : inline-block;
    vertical-align : top;
    position: relative;
    padding-left   : $grid-gutter-width;
    width          : 100%;
    margin-bottom  : $grid-gutter-width;
  }
}