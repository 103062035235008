//////////////////////
//Fonts
//////////////////////

@font-face {
	font-family: 'bricons';
	src:url('../fonts/bricons/bricons.eot?-m069ct');
	src:url('../fonts/bricons/bricons.eot?#iefix-m069ct') format('embedded-opentype'),
		url('../fonts/bricons/bricons.woff?-m069ct') format('woff'),
		url('../fonts/bricons/bricons.ttf?-m069ct') format('truetype'),
		url('../fonts/bricons/bricons.svg?-m069ct#bricons') format('svg');
	font-weight: normal;
	font-style: normal;
	
}

@font-face {
    font-family: 'oldstd';
    src: url('../fonts/oldstd/oldstandard-italic-webfont.eot');
    src: url('../fonts/oldstd/oldstandard-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oldstd/oldstandard-italic-webfont.woff2') format('woff2'),
         url('../fonts/oldstd/oldstandard-italic-webfont.woff') format('woff'),
         url('../fonts/oldstd/oldstandard-italic-webfont.ttf') format('truetype'),
         url('../fonts/oldstd/oldstandard-italic-webfont.svg#old_standard_ttitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserrat';
    src: url('../fonts/montserrat/montserrat-light-webfont.eot');
    src: url('../fonts/montserrat/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat/montserrat-light-webfont.woff2') format('woff2'),
         url('../fonts/montserrat/montserrat-light-webfont.woff') format('woff'),
         url('../fonts/montserrat/montserrat-light-webfont.ttf') format('truetype'),
         url('../fonts/montserrat/montserrat-light-webfont.svg#montserratlight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto/robotocondensed-regular-webfont.eot');
    src: url('../fonts/roboto/robotocondensed-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto/robotocondensed-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto/robotocondensed-regular-webfont.woff') format('woff'),
         url('../fonts/roboto/robotocondensed-regular-webfont.ttf') format('truetype'),
         url('../fonts/roboto/robotocondensed-regular-webfont.svg#roboto_condensedregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: 'bricons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-bricon:before {
	content: "\e600";
}

.icon-britelayer:before {
	content: "\e601";
}

.icon-cross:before {
	content: "\e117";
}

.icon-menu:before {
	content: "\e120";
}

