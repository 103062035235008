//////////////////////
//Config
//////////////////////

$colors : (
        silver-sand: #c2c6ca,
        red-ribbon: #ec0928,
        white: #ffffff,
        wood-smoke: #151516,
        black: #000000,
        seashell: #f1f1f1,
        alabaster: #f7f7f7
);

$beautybush : #f3caca;
$tasman : #d7ddd8;
$burntumber : #90253f;
$canonpink : #9c4b65;
$sail : #a1daf8;
$lynch : #5c7186;
$deepblush : #e7698f;
$redlink : $canonpink $canonpink $canonpink $canonpink underline underline;

$base-color : $burntumber;
$font--main : 'roboto', sans-serif;
$font--secondary : 'montserrat', sans-serif;
$font--italic : 'oldstd', serif;
$color--main : color(red-ribbon);
$color--secondary : color(silver-sand);
$color__background : color(alabaster);
$color__text : color(wood-smoke);
$color__text__link : $color__text;
$color__text__link--visited : $color__text;
$color__text__link--hover : $color__text;
$color__text__link--active : $color--main;

$grid-gutter-width : 20px;
$grid-columns : 12 !default;

$screen-xs : 480px;
$screen-small : 768px;
$screen-medium : 992px;
$screen-large : 1200px;

$wrapper-width : 1175px;

