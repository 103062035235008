.iphoneApp{	
	position: relative;

    &__image, &__bar{
      width: 100%;
      height: 300px;
      position: relative;
    }

	&__image{
		content: '';
		background-image: url('../img/hipsterApp.jpg');
		background-position: center center;
	}
	
	&__bar{

        &:before{
          content:'';
          height: 100%;
          position: relative;
          vertical-align: middle;
          display: inline-block;
          margin-left: -0.35em;
        }

		color: color(white);
		background-color: color(red-ribbon);
		margin: 0;
	}
	
	.title--secondary{
		line-height: 50px;
	}

    &__title__wrapper{
      vertical-align: middle;
      display: inline-block;
      text-align: center;
      padding-left: 0;
      width: 100%;

      @include breakpoint($screen-small){
        text-align: left;
        padding-left: (2 * $grid-gutter-width);
        width: auto;
      }
    }
}