//////////////////////
//Clients
//////////////////////

#clients{
	text-align: left;
	
	.text__block{
		margin-bottom: 30px;
		text-align: center;
	}
}

.clients{
    font-family: $font--main;
    color: $color__text;

  .title--secondary{
    text-align: center;
  }

  &__image{
    width: 90px;
    margin-right: $grid-gutter-width;
    border-radius: 200px;
  }

  &__info{
    font-family: $font--secondary;
    @include font-size(7);
    @include breakpoint($screen-xs){
      @include font-size(8);
    }
  }

  &__title{
    @include font-size(12);
    font-family: $font--main;
    font-weight: normal;
  }

  &__title, &__text{
    margin: 0 0 5px 0;
  }

  &__link{
    @include link-color($hover: $color--main, $deco: 'underline');
  }

}