.hero {
  position            : relative;
  width               : 100%;
  background-image    : url('../assets/img/hero.jpg');
  background-position : center center;
  background-size: cover;
  text-align          : center;
  padding-top         : 200px;
  color               : white;
  @include font-size(20);

  @include breakpoint($screen-xs) {
    @include font-size(25);
  }

  @include breakpoint($screen-small) {
    @include font-size(40);
    height : 500px;
  }

  .hero__title {
    font-family    : $font--main;
    font-size      : 0.75em;
    font-weight    : normal;
    text-transform : uppercase;
    margin         : 0 0 0 0;
    z-index        : 10;
    position       : relative;
  }

  .hero__subtitle {
    font-family   : $font--secondary;
    font-size     : 0.5em;
    z-index       : 10;
    position      : relative;
    color         : $lynch;
    margin-bottom : 0px;
  }

  .hero__bl-logo {
    width                  : 3.2em;
    position               : absolute;
    margin-left            : -1.6em;
    left                   : 50%;
    z-index                : 10;
    top                    : 40px;
    -ms-interpolation-mode : bicubic;
  }

  &__cta {
    margin-top     : 20px;
    box-sizing     : border-box;
    padding        : 0.75em;
    border         : 3px solid $deepblush;
    background     : $deepblush;
    border-radius  : 3px;
    color          : white;
    font-size      : 0.20em;
    text-transform : uppercase;
    font-family    : $font--secondary;
    cursor         : pointer;

    @include link-color(white, white, $deepblush, white);

    &:hover {
      background-color : transparent;
    }
  }
}