//////////////////////
//Location
//////////////////////

.half-bar--red {
  background-color : $base-color;
  width            : 50%;
  height           : 300px;
  padding          : 70px;
  color            : white;
}

.location {
  &__maps, &__bar {
    width  : 100%;
    height : 300px;
  }

  &__bar {
    text-align       : center;

    &:before {
      content        : '';
      margin-left    : -0.35em;
      display        : inline-block;
      vertical-align : middle;
      position       : relative;
      height         : 100%;
    }

    @include breakpoint($screen-small) {
      text-align   : left;
      padding-left : (2 * $grid-gutter-width);
    }
  }
  &__text {
    font-family  : $font--main;
    @include font-size(12);
    text-spacing : 1px;
    line-height  : 40px;

    a {
      @include link-color($lynch, $lynch, $lynch, $lynch, none, underline);
    }
  }

  &__text__wrapper {
    vertical-align : middle;
    display        : inline-block;
    position       : relative;
  }
}

.gm-style-iw * {
  display : block;
  width   : 100%;
}

.gm-style-iw h4, .gm-style-iw p {
  margin  : 0;
  padding : 0;
}

.gm-style-iw a {
  color : #4272db;
}

