//////////////////////
//Main Style
//////////////////////
@import "partials/includes/breakpoint";
@import 'partials/functions';
@import 'partials/config';
@import 'vendor/main';
@import 'vendor/normalize';
@import 'partials/components';
@import 'partials/fonts';
@import 'partials/header';
@import 'partials/footer';
@import 'partials/clients';
@import 'partials/work';
@import 'partials/location';
@import 'partials/about';
@import 'partials/together';
@import 'partials/iphoneApp';
@import 'partials/hero';
@import 'partials/grid';

* {
  box-sizing : border-box;
}

body {
  background-color : $color__background;
  margin-top       : 60px;
  @include breakpoint($screen-small) {
    margin-top : 100px;
  }
}

.inline {
  display        : inline-block;
  vertical-align : top;
  position       : relative;
}

.block {
  display  : block;
  position : relative;
}

.section {
  margin-top    : 100px;
  margin-bottom : 100px;
}

.container {
  width  : 960px;
  margin : 0 auto;
}

.horizontal {
  width           : 100%;
  height          : 400px;
  background-size : cover;
  text-align      : center;
  padding-top     : 130px;
  color           : white;

  .horizontal__title {
    font-family : $font--italic;
    @include font-size(40);
    font-weight : normal;
    margin      : 0 0 0 0;
  }

  .horizontal__subtitle {
    font-family : $font--secondary;
    @include font-size(14);
    margin      : 0;
    line-height : 38px;
  }
}

.texture_overlay {
  &:after {
    width      : 100%;
    height     : 100%;
    //content: '';
    position   : absolute;
    top        : 0;
    left       : 0;
    background : url('../img/grid.png');
    z-index    : 5;
  }

  &:before {
    width      : 100%;
    height     : 100%;
    //content: '';
    position   : absolute;
    top        : 0;
    left       : 0;
    background : rgba(color(red-ribbon), 0.3);
    z-index    : 4;
  }
}

.title {
  font-family : $font--main;
  @include font-size(25);
  margin      : 0;
}

.title--secondary {
  font-family    : $font--main;
  font-weight    : normal;
  text-transform : uppercase;
  @include font-size(14);
  margin         : 0;
  margin-bottom  : $grid-gutter-width;
}

.text--main {
  font-family : $font--main;
  @include font-size(10);
  margin      : 0;
}

.link {
  cursor : pointer;
  @include link-color($redlink...);

  &--white {
    @include link-color(white, white, white, white, underline, underline);
  }

  &--dark {
    @include link-color($lynch, $lynch, $lynch, $lynch, underline, underline);
  }
}

.bg {
  &--beta {
    background-color : $sail;
    color: $lynch;
  }
}