//////////////////////
//Header
//////////////////////

.menu {
  width            : 100%;
  height           : 100px;
  top              : 0px;
  z-index          : 1000;
  position         : fixed;
  background-color : color(white);
  box-shadow       : 0px 0px 2px rgba(color(black), .2);
  transition       : all 0.3s ease;

  .icon-bricon {
    width           : 10%;
    text-align      : center;
    text-decoration : none;
    &:before {
      @include font-size(25);
      color       : $color--main;
      line-height : 100px;
      transition  : all 0.3s ease;
    }
  }

  .icon-bricon, &__button {
    &:hover {
      background-color : $color__background;
    }
  }

  &__button {
    position       : relative;
    line-height    : 100px;
    transition     : all 0.3s ease;
    width          : 18%;
    text-align     : center;
    @include font-size(10);
    text-transform : uppercase;
    font-family    : $font--main;
    @include link-color($sail, $sail, $sail, $sail);
    cursor         : pointer;
  }

  &__button.selected {
    color : $sail;

    &:after {
      position         : absolute;
      content          : '';
      bottom           : 0;
      height           : 3px;
      display          : block;
      width            : 80%;
      left             : 10%;
      background-color : $sail;
    }
  }

  &__logo {
    position    : relative;
    line-height : 100px;
    width       : 9%;

    img {
      display        : inline-block;
      height         : 4em;
      vertical-align : middle;
    }
  }

  &--main {
    display : none;

    @include breakpoint($screen-small) {
      display : block;
    }
  }

  &--mobile {
    width       : 100%;
    height      : auto;
    max-height  : 1000px;
    overflow    : hidden;
    line-height : initial;

    &.close {
      max-height : 60px;
    }

    &__openclose {
      position : absolute;
      display  : block;
      left     : auto;
      right    : $grid-gutter-width;
      z-index  : 1001;
      color    : $sail;

      &:before {
        line-height : 60px;
        @include font-size(25);
      }
    }

    .icon-bricon {
      display : block;
    }

    .mobile__logo {
      display     : block;
      line-height : 60px;
      width       : 100%;
      text-align  : center;

      img {
        height  : 45px;
        display : inline-block;
      }
    }

    .icon-bricon,
    .menu__button {
      width       : 100%;
      line-height : 60px;

      &:before {
        line-height : 60px;
      }
    }

    @include breakpoint($screen-small) {
      display : none;
    }
  }

  &.thin {
    height : 60px;

    .icon-bricon:before {
      line-height : 60px;
    }

    .menu__button, .menu__logo {
      line-height : 60px;
    }

    .menu__logo img {
      height : 3em;
    }
  }
}