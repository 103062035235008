//////////////////////
//Work together
//////////////////////

.together{

    .layout__item{
      @include font-size(12);
      font-family: $font--main;
    }

	.text__block{
		text-align: center;
	}
	.work{
		width: 33%;
		padding: 10px;
	}
	.work__image{
		width: 100%;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	.work__info{
		background-color: color(white);
		@include font-size(8);
		font-family: $font--secondary;
		padding: 20px;
		min-height: 170px;
	}
	.work__title{
		@include font-size(12);
		font-family: $font--main;
		font-weight: normal;
		margin: 0 0 10px 0;
	}
	.work__text{
		margin: 0;
	}

	.text__block--half{
		width: 50%;
		padding: 0 10px;
		@include font-size(12);
		font-family: $font--main;
		margin: 0 0 30px 0;
		line-height: 30px;
		text-align: left;
	}
}

