$grid-size-map: (
        xs: $screen-xs,
        sm: $screen-small,
        md: $screen-medium,
        lg: $screen-large
);

@each $size-name, $size-value in $grid-size-map{
  @include breakpoint($size-value){
    @for $i from 1 through $grid-columns{
      .col-#{$size-name}-#{$i}{
        width: percentage($i / $grid-columns);
      }
    }
  }
}