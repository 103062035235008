//////////////////////
//Work
//////////////////////

#tastes {
  .text__block {
    margin-bottom : 30px;
    text-align    : center;
  }
  .work {
    width   : 33%;
    padding : 10px;
  }
  .work__image {
    width                   : 100%;
    border-top-left-radius  : 10px;
    border-top-right-radius : 10px;
  }
  .work__info {
    font-family      : $font--secondary;
    padding          : 20px;
    min-height       : 220px;
    color            : black;
    background-color : color(white);
    @include font-size(8);
  }
  .work__title {
    @include font-size(12);
    font-family : $font--main;
    font-weight : normal;
    margin      : 0 0 10px 0;
  }
  .work__text {
    margin              : 0;
    list-style-position : outside;
  }
}