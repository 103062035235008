//////////////////////
//Functions
//////////////////////

@mixin font-size($sizeValue : 1.6) {
  font-size : ($sizeValue * 1) + px;
  font-size : ($sizeValue * 0.1) + rem;
}

@mixin link-color($link : $color__text__link,
$visit : $color__text__link--visited,
$hover : $color__text__link--hover,
$active : $color__text__link--active,
$deco : none,
$hoverdeco : none) {

  text-decoration : $deco;
  &:link {
    color : $link;
  }
  &:visited {
    color : $visit;
  }
  &:hover {
    color           : $hover;
    text-decoration : $hoverdeco;
  }
  &:active {
    color : $active;
  }
}

@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }

  @warn "Unknown '#{$key}' in $colors.";
  @return null;
}